body { 
  text-align: center; 
  background: url(https://images.a-scend2.com/v2/images/a-lign-bg.png) center center/cover no-repeat fixed; 
  font: 20px Helvetica, sans-serif; 
  color: #333; 
  padding: 20px;
}
article { 
  text-align: left; 
  width: auto; 
  height: auto;
  max-width: 650px;
  margin: auto;
  padding: 45px; 
  border-radius: 20px; 
  background: #FFF; 
  box-shadow: 1px 3px 6px 0 rgba(0, 0, 0, 0.5); 
}
a { 
  color: #dc8100; 
  text-decoration: none; 
}
a:hover { 
  color: #333; 
  text-decoration: none; 
}
